.social-container {
    /* background: #eee; */
    padding: 25px 50px;
}
a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}
a.social:hover {
    transform: translateY(-2px);
} 
a.youtube {
    color: #eb3223;
}  
a.facebook {
    color: #4968ad;
} 
a.twitter {
    color: #49a1eb;
} 
a.instagram {
    color: #bd20a8;
}